import React, { lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const Thawte = lazy(() => import('sections/hero/Thawte.js'));
const PreciosThawte = lazy(() => import('sections/services/PreciosThawte.js'));
const EspecSSLThaw = lazy(() => import('sections/services/EspecSSLThaw.js'));
const BotonSanGeo = lazy(() => import('sections/services/BotonSanGeo.js'));
const Garantia30g = lazy(() => import('sections/hero/Garantia30g.js'));
const BannerSSL = lazy(() => import('sections/hero/BannerSSL.js'));
const BotonSSL = lazy(() => import('sections/hero/BotonSSL.js'));
const SelloSitelock = lazy(() => import('sections/about/SelloSitelock.js'));
const SeguridadInformatica = lazy(() => import('sections/about/SeguridadInformatica.js'));
const FaqDigicert = lazy(() => import('sections/services/FaqDigicert.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {
    
    
    return (
      <div>
        <Helmet>
          <title>Certificados SSL Thawte: Pereira, Medellín, Cali, Colombia</title>
          <meta name="description" content="Obtén certificados SSL Thawte para tus sitios web en Pereira, Medellín, Cali y toda Colombia. Asegura la confianza de tus usuarios con cifrado sólido y seguro." />
        </Helmet>
        <Layout>
          <Thawte />           
          <PreciosThawte />
          <EspecSSLThaw />
          <Garantia30g />
          <BotonSanGeo />          
          <BannerSSL />
          <BotonSSL />
          <SelloSitelock />
          <SeguridadInformatica />          
          <FaqDigicert />
          <BotonSanGeo />          
          <Llamenos />
          <ContactCreative />
          <Footer />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`